import React from 'react';
import css from './ShowCaseItem.module.css';

function ShowCaseSection({ children }) {
  return (
    <div
      className={`grid lg:grid-cols-3 md:grid-cols-1 max-w-5xl mx-8 md:mx-12 lg:mx-auto gap-8 md:gap-8 py-12 ${css.parent}`}
    >
      {children}
    </div>
  );
}

export default ShowCaseSection;
