import React from 'react';
import { Link } from 'react-router-dom';
import css from './FaqSection.module.css';
function FaqSection() {
  return (
    <div className={`${css.root} max-w-5xl mx-auto rounded-xl`}>
      <div className="flex flex-col justify-center h-full w-full mx-12">
        <h1 className="text-white">Need help?</h1>
        <Link
          to="/faq"
          role="button"
          className={
            'shadow-lg hover:no-underline block w-[170px] bg-white rounded-md text-center py-3  text-black font-semibold border-2 border-solid border-white text-base md:text-sm ml-2 cursor-pointer hover:bg-gray-100 hover:text-black hover:border-gray-100 transition ease-in-out duration-150'
          }
        >
          View our FAQs
        </Link>
      </div>
    </div>
  );
}

export default FaqSection;
