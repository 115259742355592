import React from 'react';
import SearchForm from './SearchForm';
import config from '../../config';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import img from '../../assets/background-1440.jpg';

function SectionSearch() {
  const history = useHistory();

  const onSearchSubmit = values => {
    const { type, keyword, location } = values;
    let searchParams = {};
    switch (type) {
      case 'keyword':
        searchParams = { keywords: keyword };
        break;
      case 'location':
        const { search, selectedPlace } = location;
        const { origin, bounds } = selectedPlace;
        const originMaybe = config.sortSearchByDistance ? { origin } : {};
        searchParams = {
          ...originMaybe,
          address: search,
          bounds,
        };
        break;
    }
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };
  return (
    <div
      style={{
        backgroundImage: `url(${img}) `,
      }}
      className="relative overflow-hidden h-[500px]"
    >
      <div className="w-[90%]  sm:w-[80%]  -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 ">
        <SearchForm onSubmit={onSearchSubmit} />
      </div>
    </div>
  );
}

export default SectionSearch;
