import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/prospaceFacebook-1200x630.jpg';
import twitterImage from '../../assets/prospaceTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import classNames from 'classnames';
import ShowCaseItem from './ShowCaseItem';
import ShowCaseSection from './ShowCaseSection';
import SectionSearch from './SectionSearch';
import img1 from '../../assets/img1.jpg';
import img2 from '../../assets/img2.jpg';
import img3 from '../../assets/img3.jpg';
import FaqSection from './FaqSection';
import { FormattedMessage } from '../../util/reactIntl';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={`max-w-5xl mx-8 md:mx-12 lg:mx-auto`}>
                <h1 className="mb-0">
                  <FormattedMessage id="SectionHowItWorks.titleLineOne" />
                </h1>
              </div>
              <ShowCaseSection>
                <ShowCaseItem
                  title="Find the perfect space for your idea"
                  link="/s"
                  btnText="Search spaces now"
                  src={img1}
                />
                <ShowCaseItem
                  title="Can’t find your space? Post your idea!"
                  link="/listing/create/requirement"
                  btnText="Help me find my custom space"
                  src={img2}
                />
                <ShowCaseItem
                  title="Do you have a business you want to list?"
                  link="/listing/create/business_share"
                  btnText="List my business now"
                  src={img3}
                />
                <div>
                  <h3 className={css.cardHeader}>
                    <Link to="/s" className={css.link}>
                      Search spaces now
                    </Link>
                  </h3>
                  <p className="leading-normal text-black">
                    <strong>We make it easy for you to find and rent spaces,</strong> so you can
                    focus on the core of your business whether it's launching a new concept,
                    increasing brand awareness or selling products. Once you found your perfect
                    space, enquire with the business owner and start your journey to success!
                  </p>
                </div>

                <div>
                  <h3 className={css.cardHeader}>
                    <Link to="/listing/create/requirement" className={css.link}>
                      Help me find my custom space
                    </Link>
                  </h3>

                  <p className="leading-normal text-black">
                    <strong>If you have a space in mind and can't find it,</strong> we've created
                    this feature just for you! Follow the prompts and have your idea posted on the
                    website. A business will see your idea and approach you or we will help find
                    that perfect space for you!
                  </p>
                </div>

                <div>
                  <h3 className={css.cardHeader}>
                    <Link to="/listing/create/business_share" className={css.link}>
                      List my business now
                    </Link>
                  </h3>
                  <p className="leading-normal text-black">
                    <strong>Be the destination for brands!</strong> Thousands are discovering and
                    booking new spaces for their business. Be the destination for them! We help
                    market you, create the listings and will be on hand to assist you at every step
                    of the process. All you need to do is sit back and watch the rental offers roll
                    in!
                  </p>
                </div>
              </ShowCaseSection>
            </li>
            <li className={css.section}>
              <FaqSection />
            </li>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations />
              </div>
            </li>

            {/* <li className={css.section}>
              <SectionSearch />
            </li> */}
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li> */}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
