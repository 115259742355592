import React from 'react';
import { useHistory } from 'react-router-dom';
import css from './ShowCaseItem.module.css';

function ShowCaseItem({ link, title, btnText, src }) {
  const history = useHistory();
  const onRouteToPage = () => history.push(link);
  return (
    <section
      onClick={onRouteToPage}
      className={`relative flex shadow flex-col cursor-pointer overflow-hidden justify-center items-start ${css.root}`}
      style={{ backgroundImage: `url(${src})` }}
    >
      {/* <div className="w-full h-full bg-black/30 absolute z-1" /> */}
      {/* <div className="relative z-10 p-2">
        <h2 className={`text-gray-100 ml-6 max-w-[300px] leading-snug ${css.textShadow}`}>
          {title}
        </h2>
        <button
          type="button"
          role="link"
          className={`shadow-lg block mt-6  ${css.buttonWidth}  bg-white rounded-md text-center py-3  text-black font-semibold border-2 border-solid border-white text-base md:text-sm ml-6 cursor-pointer hover:bg-gray-100 hover:text-black hover:border-gray-100 transition ease-in-out duration-150`}
          onClick={onRouteToPage}
        >
          {btnText}
        </button>
      </div> */}
    </section>
  );
}

ShowCaseItem.defaultProps = {
  link: null,
  text: null,
  btnText: null,
};

export default ShowCaseItem;
